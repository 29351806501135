import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Check from '../views/Check.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: HomeView
    // },
    {
      path: '/check',
      name: 'check',
      component: Check
    },
    {
      path: `/liff/:resource_id*`,
      component: () => import('@/views/LIFFViews/LIFFBase.vue'),
      props: route => {
        // return { route, params: route.params, query: route.query }
      }
    }

  ]
})

// router.onError((error, to) => {
//   if (error.message.includes('Failed to fetch dynamically imported module')) {
//     window.location = to.fullPath as any
//   }
// })

export default router
