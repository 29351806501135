<script setup>

</script>

<template>
  <div class="app">
  <!-- <div>
    LINE LIFF
  </div> -->
  <RouterView />
  </div>
</template>

<style>
body {
  background: white;
}

.app {
  background: white;
}
</style>
